import { Component, Mixins } from 'vue-property-decorator';

import OpasCalendarModal from '~/components/OpasCalendarModal/OpasCalendarModal.vue';
import { moment } from '~/shared/moment';
import Toggler from '~/components/Toggler.vue';
import { VueCal } from '~/shared/vuecal';
import { getTextColorFromHex } from '~/shared/color-utils';
import { OpasEventsQuery, OpasEventsQueryVariables } from '~/graphql';
import VueCalMixin from '~/mixins/VueCalMixin';

@Component({
  components: {
    VueCal,
    Toggler,
    OpasCalendarModal,
  },
  apollo: {
    all: {
      query: OpasEventsQuery,
      variables (this: OpasCalendar): OpasEventsQueryVariables {
        return {
          from: this.date.from.format(),
          to: this.date.to.format(),
        };
      },
      update (this: OpasCalendar, data: OpasEventsQuery) {
        this.opasLastUpdated = data.opasLastUpdated;
        this.opasEvents = data.opasEvents;
      },
    },
  },
})
export default class OpasCalendar extends Mixins(VueCalMixin) {
  moment = moment
  date = {
    today: moment(),
    from: moment().startOf('month'),
    to: moment().endOf('month'),
  }

  opasLastUpdated: OpasEventsQuery['opasLastUpdated'] = null;
  opasEvents: OpasEventsQuery['opasEvents'] = [];

  activeEventId: string | null = null;

  get events () {
    return this.opasEvents
      .map((event) => {
        const start = moment(event.startDate).format('YYYY-MM-DD HH:mm');
        let end = moment(event.endDate).format('YYYY-MM-DD HH:mm');
        const allDay = start === end;

        if (allDay)
          end = moment(event.endDate).add(1, 'day').format('YYYY-MM-DD HH:mm');

        return {
          id: event.id,
          start,
          end,
          allDay,
          title: event.eventType.name,
          color: event.eventType.color,
          textColor: getTextColorFromHex(event.eventType.color),
          location: event.location?.name,
          project: event.project.name,
        };
      });
  }

  get activeEvent () {
    return this.opasEvents
      .find(b => b.id === this.activeEventId);
  }

  openEvent (id: string) {
    this.activeEventId = id;
    // this.$bvModal.show('calendar-modal');
  }

  onViewChange ({ startDate, endDate }: { startDate: Date, endDate: Date }) {
    this.date.from = moment(startDate).subtract(1, 'month').startOf('month');
    this.date.to = moment(endDate).add(1, 'month').endOf('month');
  }
}
