import { Component, Model, Vue, Watch } from 'vue-property-decorator';

import { moment } from '~/shared/moment';
import { OpasDuty, OpasEventQuery, OpasEventQueryVariables } from '~/graphql';

@Component({
  apollo: {
    opasEvent: {
      query: OpasEventQuery,
      variables (this: OpasCalendarModal): OpasEventQueryVariables {
        return { id: this.eventId! };
      },
      skip (this: OpasCalendarModal) {
        return !this.eventId;
      },
    },
  },
})
export default class OpasCalendarModal extends Vue {
  moment = moment;

  @Model('close', { type: String, default: () => null })
  eventId!: string | null;

  opasEvent!: OpasEventQuery['opasEvent'];

  get modalSize () {
    return 'xl';
  }

  get facts () {
    if (!this.opasEvent)
      return [];

    const e = this.opasEvent;
    return [
      { name: 'Vecka', value: e.week },
      e.startDate !== e.endDate && { name: 'Tid', value: `${moment(e.startDate).format('HH:mm')} - ${moment(e.endDate).format('HH:mm')}` },
      { name: 'Projekt', value: e.project?.name },
      { name: 'Dirigent', value: e.conductor?.name },
      { name: 'Orkester', value: e.orchestra?.name },
      { name: 'Klädsel', value: e.dress?.name },
      { name: 'Max-Instrumentation', value: e.maxInstrumentation },
    ].filter(Boolean);
  }

  get groupedDuties () {
    if (!this.opasEvent)
      return null;

    const instrumentGroups = this.opasEvent.instruments
      .map(instrument => ({
        ...instrument,
        duties: [] as Partial<OpasDuty>[],
      }));

    this.opasEvent.duties.forEach((d) => {
      const instrument = instrumentGroups.find(i => i.id === d.instrument.id);
      if (instrument)
        instrument.duties.push(d as any);
    });

    return instrumentGroups.filter(i => i.duties.length > 0);
  }

  get isCorrectColumn () {
    /**
     * Place items in one column based on the total amount of rendered items
     */
    return (columns, currentColumn, instrumentIndex) => {
      const total = this.opasEvent.duties.length;
      let previousThreshold = -1;

      for (let n = 1; n < columns; n++) {
        let i = 0;
        const index = this.groupedDuties.findIndex((instrument) => {
          i += instrument.duties.length;
          // Weight list to the left columns to prevent right column from being the tallest
          return Math.floor(i * 0.90) >= Math.floor((n * total) / columns);
        });

        if (currentColumn === n)
          return instrumentIndex > previousThreshold && instrumentIndex <= index;

        previousThreshold = index;
      }

      // Last column
      return instrumentIndex > previousThreshold;
    };
  }

  @Watch('eventId')
  onModalToggled (eventId: string | null) {
    const modal: any = this.$refs.modal;
    if (eventId)
      modal.show();
    else
      modal.hide();
  }

  closeModal () {
    this.$emit('close', null);
  }
}
